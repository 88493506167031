


























const NUMBERS = Array.from('0123456789')
const FUNCTIONAL = ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'] as const
const BASE_INPUT_CURRENCY_ALLOWED_KEYS =
  [...NUMBERS.map(n => `Digit${n}`), ...NUMBERS.map(n => `Numpad${n}`), ...FUNCTIONAL] as const

export default {
  name: 'BaseInputCurrency',
  props: {
    title: {
      type: String
    },
    currency: {
      type: String,
      default: '$'
    },
    value: {
      type: Number
    },
    error: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  emits: ['input'],
  computed: {
    modelValue: {
      get (): number {
        return this.value
      },
      set (value: number | string): void {
        const v = typeof value === 'string' ? parseFloat(value) : value
        this.$emit('input', isFinite(v) ? v : 0)
      }
    }
  },
  methods: {
    keydown (event: KeyboardEvent): void {
      if (!BASE_INPUT_CURRENCY_ALLOWED_KEYS.includes(event.code)) {
        event.preventDefault()
      }
    }
  }
}
