import type { UnionServices } from '@/models/IUserUses'
import type { AutoPostBoostingErrors } from '@/views/AutoPostBoosting/AutoPostBoostingEdit.vue'
import type { PropType } from 'vue'

export interface IProps<T = unknown> {
  props: {
    service: {
      type: PropType<UnionServices>,
      required: true
    }
    ruleId: {
      type: PropType<`${number}`>,
      default: null
    }
    data: {
      type: PropType<T>,
      default: null
    }
    serverData: {
      type: PropType<T>,
      default: null
    },
    meta: {
      type: PropType<unknown>,
      default: null
    },
    errors: {
      type: PropType<AutoPostBoostingErrors>,
      default: () => AutoPostBoostingErrors
    }
  }
}

export default {
  props: {
    service: {
      type: String as PropType<UnionServices>,
      required: true
    },
    ruleId: {
      type: String as PropType<`${number}`>,
      default: null
    },
    data: {
      type: Object as PropType<unknown>,
      default: null
    },
    serverData: {
      type: Object as PropType<unknown>,
      default: null
    },
    meta: {
      type: Object as PropType<unknown>,
      default: null
    },
    errors: {
      type: Object as PropType<AutoPostBoostingErrors>,
      default: (): AutoPostBoostingErrors => ({})
    }
  }
}
