




















import type { AutoPostBoostingError } from '@/views/AutoPostBoosting/AutoPostBoostingEdit.vue'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'AutoPostBoostingErrors',
  props: {
    errors: {
      type: [Object] as PropType<AutoPostBoostingError | { text: string } | undefined>,
      default () { return null }
    }
  }
})
